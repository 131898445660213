import React from "react";
import { GetStaticProps, NextPage } from "next";
import { IPageLayoutProps } from "../components/PageLayout";
import ApiEngine from "../api-engine";
import TemplatePageComponent from "../components/pages/TemplatePageComponent";
import { ApiPage } from "../models/api/page.model";
import { ITemplatePageProps } from "./[slug]";
import NavigationProvider from "../context/NavigationContext";

export interface IHomePageProps extends IPageLayoutProps {
  content: ApiPage;
}

const HomePage: NextPage<IHomePageProps> = (props) => {
  return (
    <NavigationProvider
      sections={props.content.attributes.body}
      menu={props.menu}
      slug={props.slug}
    >
      <TemplatePageComponent {...props} />
    </NavigationProvider>
  );
};

export const getStaticProps: GetStaticProps<ITemplatePageProps> = async (
  context
) => {
  const slug = "accueil";
  const content = await ApiEngine.pages.getBySlug(slug);
  const menu = await ApiEngine.menu.getOrderedMenu();
  return {
    props: { content, menu, slug },
    revalidate: 30,
  };
};

export default HomePage;
